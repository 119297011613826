import React, { ReactElement } from "react"
import { Layout } from "./../components/Layout"
import SuoraChristian from "../images/suora-christian.jpeg"
import { graphql } from "gatsby"

const Emphasize: React.FunctionComponent = ({ children }) => (
  <span className="text-primary-600 font-bold">{children}</span>
)
function Imprint({ data }: any): ReactElement {
  return (
    <Layout>
      <main>
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
          Imprint <Emphasize>/</Emphasize> Privacy<Emphasize>.</Emphasize>
        </h1>

        <div
          className="markdown"
          dangerouslySetInnerHTML={{
            __html: data.imprint.edges[0].node.childMarkdownRemark.html,
          }}
        ></div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ImprintQuery {
    imprint: allFile(
      filter: {
        name: { eq: "imprint" }
        sourceInstanceName: { eq: "markdown" }
      }
    ) {
      edges {
        node {
          name
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default Imprint
